import toast, {Toaster} from 'react-hot-toast';
export default function toastAlert(type, message) {
 
  if (type == 'success') {
    toast.success(message, {
     
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    });
  }
  if (type == 'error') {
    toast.error(message, {
    
      style: {
        borderRadius: '10px',
        background: 'red',
        color: '#fff',
      },
    });
  }
  if (type == 'info') {
    toast(message, {
      icon: 'ℹ',
      style: {
        borderRadius: '10px',
        fontWeight: 700,
        letterSpacing: '1px',
        background: 'blue',
        color: '#fff',
      },
    });
  }

  if (type == 'loading') {
    toast(message, {
      // duration: 3000,
      style: {
        borderRadius: '10px',
        fontWeight: 700,
        letterSpacing: '1px',
        background: 'orange',
        color: '#fff',
      },
    });
  }

  return (
    <div>
      <Toaster toastOptions={{
          // Define default options
          duration: 8000,
          style: {
            zIndex: 2147483647,
          },
        }} position="top-center" />
    </div>
  );
}
