import "./App.css";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Emailverification from "./pages/Emailverification";
import OtpVerification from "./pages/Otpverification";
import SetPassword from "./pages/Setpassword";
import Winner from "./pages/Winner";
import Wallet from "./pages/Wallet";
import Paypalsuccess from "./pages/Paypalsuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import UpdatePassword from "./pages/Updatepassword";
import Termsandconditions from "./pages/Termsandconditions";
import Editprofile from "./pages/Editprofile";
import Editpassword from "./pages/Editpassword";
import Deleteaccount from "./pages/Deleteaccount";
import Contactus from "./pages/Contactus";
import History from "./pages/History";
import endpoint from "./Endpointurl";
import Paypalcancel from "./pages/Paypalcancel";
import PrivateRoute from "./pages/PrivateRoute";
import PublicRoute from "./pages/PublicRoute";
import url from "./url";
import { io } from "socket.io-client";
import NewDashboard from "./pages/NewDashboard";
import SocketHandler from "./pages/SocketHandler";
import * as Sentry from "@sentry/react";



function App() {
  Sentry.init({
    dsn: "https://a5f85f4497f7d9a14bccde69277a4444@o4508500142718976.ingest.us.sentry.io/4508500146978816",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/backend-v2\.cueballdash\.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: true, // Enable debug logs
  });
  useEffect(() => {
    // localStorage.clear()
    const appVersion = "1.0.1"; // Increment this with each new release
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== appVersion) {
      localStorage.clear();
      localStorage.setItem("appVersion", appVersion);
      alert("Session expired. Please log in again.");
      window.location.href = endpoint;
    }
  }, []);
  return (
    <>
      <SocketHandler />
      <Routes>
        <Route
          path={`${endpoint}`}
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}signup`}
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}emailverification`}
          element={
            <PublicRoute>
              <Emailverification />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}otpverification`}
          element={<OtpVerification />}
        />
        <Route path={`${endpoint}setpassword`} element={<SetPassword />} />


        <Route
          path={`${endpoint}dashboard`}
          element={
            <PrivateRoute>
              <NewDashboard />
            </PrivateRoute>
          }
        />


        <Route
          path={`${endpoint}winner`}
          element={
            <PrivateRoute>
              <Winner />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}wallet`}
          element={
            <PrivateRoute>
              <Wallet />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}success`}
          element={
            <PrivateRoute>
              <Paypalsuccess />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}privacypolicy`}
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}termsconditions`}
          element={
            <PrivateRoute>
              <TermsConditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}updatepassword`}
          element={
            <PublicRoute>
              <UpdatePassword />
            </PublicRoute>
          }
        />
        <Route
          path={`${endpoint}termsandconditions`}
          element={
            <PrivateRoute>
              <Termsandconditions />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}history`}
          element={
            <PrivateRoute>
              <History />
            </PrivateRoute>
          }
        />

        <Route
          path={`${endpoint}editprofile`}
          element={
            <PrivateRoute>
              <Editprofile />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}changepassword`}
          element={
            <PrivateRoute>
              <Editpassword />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}deleteaccount`}
          element={
            <PrivateRoute>
              <Deleteaccount />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}contactus`}
          element={
            <PrivateRoute>
              <Contactus />
            </PrivateRoute>
          }
        />
        <Route
          path={`${endpoint}cancel`}
          element={
            <PrivateRoute>
              <Paypalcancel />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
