import { io } from "socket.io-client";

let socket;

export const initializeSocket = (url) => {
  if (!socket) {
    socket = io(url);
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    throw new Error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};
