import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import i18n from './multiLingual';
import { Toaster } from "react-hot-toast";
import { Provider } from 'react-redux';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId="366403309807-9eglv7mjfvct4oj1rjtmkr6jnb9mrkju.apps.googleusercontent.com">
      <Provider store={store}>
        <React.StrictMode>
          <App />
          <Toaster
            position="top-center"
            toastOptions={{
              className: "react-hot-toast",
              style: {
                zIndex: 2147483647,
              },
            }}
          />
        </React.StrictMode>
      </Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

reportWebVitals();
