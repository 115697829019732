import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputfieldCom from "../components/items/InputfieldCom";
import { Avatar, Box, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import ButtonMD from "../components/items/ButtonMD";
import { useNavigate } from "react-router-dom";
import url from "../url";
import Sidebar from "../components/sidebar/Sidebar";
import background from "../Assets/background.PNG";
import endpoint from "../Endpointurl";
import alert from "../Assets/alert.png";
import ModalWarning from "../components/items/ModalWarning";
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";

function Deleteaccount() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [profiledetails, setProfiledetails] = useState("");

    const details = useSelector(state => state.user.userProfile);

    useEffect(() => {

        // const details = JSON.parse(localStorage.getItem('profiledetails'));
        if (details) {
            setProfiledetails(details);
        }

    }, []);

    const [opensuccess, setOpensuccess] = useState(false);

    const deleteaccount = () => {
        setLoading(true);
        setTimeout(() => {
            var InsertAPIURL = `${url}user/delete_user`
            var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            };
            var Data = {
                "email": profiledetails?.data?.email
            };
            fetch(InsertAPIURL, {
                method: 'DELETE',
                headers: headers,
                body: JSON.stringify(Data),
            })
                .then(response => response.json())
                .then(response => {
                    setLoading(false);
                    console.log(response)
                    if (response.error) {
                        setLoading(false);
                        toastAlert("error", "Something went wrong. Please try again later");
                       
                    } else {
                        toastAlert("success", response.message);
                       

                        setTimeout(() => {
                            setLoading(false);
                            navigate(`${endpoint}`)
                        }, 2000)

                    }
                }
                )
                .catch(error => {
                    setLoading(false);
                    toastAlert("error", "Something went wrong. Please try again later");
                    
                });
        }, 2000)
    }



    return (
        <>
            <Sidebar
                componentData={
                    <Box
                        sx={{
                            backgroundImage: `url(${background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100vh",
                            overflow: "hidden"
                        }}
                    >
                        <Container>
                            <Typography variant='h6' color="#F5BC01" align="center" fontFamily="Pacifico" fontSize={{ xs: "27px", sm: "37px", md: "57px" }} pt={4} mb={1}  >
                                {t("Delete Account")}
                            </Typography>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
                                <Card sx={{ mt: { xs: 0, md: 0 }, p: 0, backgroundColor: "transparent", borderRadius: "10px", boxShadow: "none", border: "1px solid transparent", width: { xs: "90%", md: "50%" } }}>
                                    <CardContent>
                                        <Stack direction="column" spacing={1}>

                                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                                <img src={alert} style={{ width: 130 }} />
                                            </div>

                                            <Typography variant='body' color="#222222" align="center" fontFamily="Rubik" fontSize="13px"
                                            >
                                                {t("By deleting your account, you will lose access to all your data associated with this account. However, you have the option to retrieve your data within 90 days of deletion by sending an email request to our administrative team at")}

                                                <br />

                                                <a href={`mailto:Cue-Ball@gmail.com`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                                                    <span style={{ color: "#F5BC01" }}>{t("Cue-Ball@gmail.com")}</span>
                                                </a>

                                            </Typography>

                                            <Typography variant='body' color="#222222" align="center" fontFamily="Rubik" fontSize="13px"
                                            >
                                                {t("In the body of the email, provide the following complete profile credentials:")}
                                            </Typography>

                                            <Typography variant='body' color="#222222" align="left" fontFamily="Rubik" fontSize="13px"
                                            >
                                                <ul>
                                                    <li>{t("Username")}</li>
                                                    <li>{t("Email Address")}</li>
                                                    <li>{t("Full Name")}</li>
                                                </ul>
                                            </Typography>

                                            <Typography variant='body' color="#222222" align="center" fontFamily="Rubik" fontSize="13px"
                                            >
                                                {t("Please ensure that the provided information matches the details associated with your deleted account.")}
                                            </Typography>

                                            <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "30px" }}>
                                                <ButtonMD variant="contained" title={t("Delete Account")} width="50%" type="submit" borderColor="orange" backgroundColor="orange" borderRadius="10px" disabled={loading} onClickTerm={() => setOpensuccess(true)} />
                                            </div>

                                        </Stack>
                                    </CardContent>
                                </Card>
                            </div>
                        </Container>
                    </Box>
                }
            />

            <ModalWarning
                open={opensuccess}
                onClose={() => setOpensuccess(false)}
        errorImage={true}

                title={t("Are you sure to delete the account?")}
                // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
                data={
                    <ButtonMD variant="contained" title={t("Yes, sure")} width="60%" type="submit" borderColor="orange" backgroundColor="orange" borderRadius="10px" disabled={loading} onClickTerm={deleteaccount} />
                }
            />

        </>
    );
}

export default Deleteaccount;
