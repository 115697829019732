import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initializeSocket } from "./socket"; // Assuming you have a socket.js file
import toastAlert from "../components/toastAlert";
import endpoint from "../Endpointurl";
import url from "../url";

const SocketHandler = () => {
  const location = useLocation();

  useEffect(() => {
    const socket = initializeSocket(url);

    const handleSocketEvent = (data) => {
      console.log("~DATSGHSDHD", data);
      const excludedPaths = [
        `${endpoint}`,
        `${endpoint}signup`,
        `${endpoint}otpverification`,
        `${endpoint}emailverification`,
        `${endpoint}updatepassword`,
        `${endpoint}changepassword`,

      ];
      if (!excludedPaths.includes(location.pathname)) {
        if(data.status==="result-anounced"){
        toastAlert("success", `Result Announced!`);

        }else{
            toastAlert("success", `Game has ${data.status}`);

        }
      }
    };

    socket.on("received-data", handleSocketEvent);

    return () => {
      socket.off("received-data", handleSocketEvent);
    };
  }, [location.pathname]);

  return null;
};

export default SocketHandler;
