import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

import jackpot from "../Assets/jackpot.png";
import winner_rectangle from "../Assets/winner_rectangle.png";
import winnerBG from "../Assets/winnerBG.png";
import { useNavigate } from "react-router-dom";
import endpoint from "../Endpointurl";
import url from "../url";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";

function Winner() {
  const [loader, setLoader] = useState(true);

  const details = useSelector(state => state.user.userProfile);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [profiledetails, setProfiledetails] = useState("");
  const [winningdetails, setWinningdetails] = useState([]);

  const getWinningDetails = (details) => {
    var InsertAPIURL = `${url}game/get_completed_games_latest_by_user_id?user_id=${details.data.user_id}`;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(InsertAPIURL, {
      method: "GET",
      headers: headers,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("winning user", response.data);
        if (response.data.length == 0 || response.data == undefined || null) {
          navigate(`${endpoint}dashboard`);
        } else {
          console.log("winning user", response.data);
          setWinningdetails(response.data);
          setLoader(false)
        }
      })
      .catch((error) => {
          setLoader(false)
          // setLoading(false);
        toastAlert("error", "Something went wrong. Please try again later");
      });
  };

  useEffect(() => {
    // const details = JSON.parse(localStorage.getItem("profiledetails"));
    if (details) {
      setProfiledetails(details);
    }

    getWinningDetails(details);
  }, []);

  return (
    <>
      <Sidebar
        componentData={
          <Box
            sx={{
              backgroundImage: `url(${winnerBG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
            }}
          >
           {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (  <>
            {winningdetails.map((item) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "100vh",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Stack mt={3.5}>
                    <Box
                      sx={{
                        position: "relative",
                        ml: { xs: 5, md: 0 },
                        width: { xs: "80%", md: "100%" },
                        height: "70px",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <Box
                        component="img"
                        src={winner_rectangle}
                        alt="Rectangle"
                        sx={{
                          width: "100%",
                          height: "100%",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <Box
                        component="img"
                        src={jackpot}
                        alt="Jackpot Icon"
                        sx={{
                          position: "absolute",
                          top: { xs: "-30%", md: "-45%" },
                          left: "-10%",
                          width: { xs: "30%", sm: "30%", md: "28%" },
                          margin: 0,
                          padding: 0,
                        }}
                      />
                      <Typography
                        variant="h5"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "30%",
                          fontSize: { xs: "15px", sm: "25px", md: "25px" },
                          fontFamily: "Rubik",
                          fontWeight: 550,
                          transform: "translateY(-50%)",
                          color: "#FFE064",
                          textAlign: "left",
                        }}
                      >
                        Jackpot
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "5%",
                          fontWeight: 550,
                          fontSize: { xs: "15px", sm: "25px", md: "25px" },
                          fontFamily: "Rubik",
                          transform: "translateY(-50%)",
                          color: "#FFE064",
                          textAlign: "right",
                        }}
                      >
                        ${" "}
                        {item?.game_status == "House Wins" ? (
                          "0"
                        ) : (
                          <>{item?.winning_amount}</>
                        )}
                      </Typography>
                    </Box>
                  </Stack>
                </div>

                {/* <div style={{ display: "flex" }}>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 2, fontSize: "20px", fontFamily: "Rubik", fontWeight: 450, color: '#11D000' }}  >
                                        Your Ball
                                    </Typography> &nbsp;&nbsp;<Avatar src={item?.user_selected_ball_image_url} sx={{ mt: 1 }} />
                                </div> */}
                <Stack direction="row" spacing={2} mt={2}>
                  <Typography
                    variant="h5"
                    sx={{
                      mt: 2,
                      mb: 2,
                      fontSize: "20px",
                      fontFamily: "Rubik",
                      fontWeight: 450,
                      color: "#11D000",
                    }}
                  >
                    Your Ball
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} mt={2}>
                  {item?.user_selected_balls.map((ball, index) => (
                    <Avatar key={index} src={ball.ball_image_url} />
                  ))}
                </Stack>

                {/* user_selected_winning_ball
                                winner_ball */}

                <Typography
                  variant="h5"
                  sx={{
                    mt: 0,
                    fontSize: { xs: "15px", md: "37px" },
                    mb: 2,
                    fontFamily: "Pacifico",
                    fontWeight: "medium",
                    textShadow: "2px 2px 0 #000",
                    color: `${item?.game_status == "House Wins"
                        ? "red"
                        : item?.game_status == "Lost"
                          ? "red"
                          : "#11D000"
                      }`,
                  }}
                >
                  {/* item?.user_selected_ball == "1" || "2" || "3" || "4" || "5" || "6" || "7" && item?.winner_ball == "8" ?
                                        "Winner"
                                        :
                                        item?.user_selected_ball == "10" || "11" || "12" || "13" || "14" || "15" && item?.Winner_ball == "9" ?
                                            "winner"
                                            : */}
                  {item?.game_status == "House Wins"
                    ? "House Wins"
                    : item?.game_status == "Lost"
                      ? "Opps! you Lose"
                      : "Winner"}
                </Typography>

                <Typography
                  variant="h5"
                  sx={{
                    mt: 0,
                    fontSize: { xs: "15px", md: "20px" },
                    fontFamily: "Rubik",
                    fontWeight: 450,
                    color: "#000000",
                  }}
                >
                  {item?.game_status == "House Wins"
                    ? "Hard luck! Try again next time. 🎱🔄"
                    : item?.game_status == "Lost"
                      ? "Hard luck! Try again next time. 🎱🔄"
                      : "Congratulations! Your ball won! 🏆💰"}
                </Typography>

                <Typography
                  variant="h6"
                  // color={`${item?.game_status == "House Wins" ? "red" : "#11D000"}`}
                  color="#11D000"
                  fontFamily="Pacifico"
                  fontSize="37px"
                  fontWeight="bold"
                  style={{
                    textShadow: "2px 2px 0 #000",
                  }}
                >
                  Winner Ball
                </Typography>

                <Avatar
                  src={item?.winner_ball_image_url}
                  sx={{
                    width: { xs: 90, md: 150 },
                    height: { xs: 90, md: 150 },
                  }}
                />

                <Typography
                  variant="h6"
                  // color="#11D000"
                  color={`${item?.game_status == "House Wins" ? "red" : "#11D000"
                    }`}
                  fontFamily="Pacifico"
                  fontSize="30px"
                >
                  {item?.game_status == "House Wins" ? (
                    "House Wins"
                  ) : (
                    <>Winners &nbsp;&nbsp; {item?.user_selected_winning_ball}</>
                  )}
                </Typography>

                <Typography
                  variant="h6"
                  color="#000000"
                  fontFamily="Rubik"
                  fontSize="20px"
                  mb={2}
                >
                  Winning Amount &nbsp;&nbsp;{" "}
                  {item.game_status == "House Win"
                    ? 0
                    : item.game_status == "Lost"
                      ? 0
                      : item?.winning_amount_single}
                </Typography>

                <Button
                  onClick={() => navigate(`${endpoint}dashboard`)}
                  variant="outlined"
                  sx={{
                    width: { xs: "60%", md: "20%" },
                    color: "#060502",
                    border: "4px solid #373737",
                    textTransform: "capitalize",
                    fontFamily: "Rubik",
                    borderRadius: "10px",
                    fontWeight: "bold",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%)", // Change text color on hover
                      border: "4px solid #373737",
                    },
                  }}
                >
                  Go to home
                </Button>
              </div>
            ))}</>)}
          </Box>
        }
      />
    </>
  );
}

export default Winner;
