import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import jackpot from "../Assets/jackpot.png";
import triangle from "../Assets/triangle.png";
import Confetti from "react-confetti";
import { useFormik } from "formik";

import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import TypographyMD from "../components/items/Typography";
import endpoint, { url_FE } from "../Endpointurl";
import { useNavigate } from "react-router-dom";
import Topbar from "../components/topbar/Topbar";
import url from "../url";
import background from "../Assets/background.PNG";
import balls1 from "../Assets/balls.png";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";
import { useSelector } from "react-redux";
import ModalWarning from "../components/items/ModalWarning";
import ButtonMD from "../components/items/ButtonMD";
import InputfieldCom from "../components/items/InputfieldCom";
import ModalAdd from "../components/items/Modal";
import * as yup from "yup";


function NewDashboard() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const validationSchema = yup.object({
    amount: yup.string().required("Amount is required"),
  });
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values, { resetForm }) => {
      console.log(values);

      setLoading(true);
      setTimeout(() => {
        const paymentData = {
          user_id: 100653,
          // game_id: 10242,
          items: [
            {
              name: "Rimsha",
              sku: "item",
              price: values.amount,
              currency: "USD",
              quantity: 1,
            },
          ],
          amount: {
            currency: "USD",
            total: values.amount,
          },
          description: "This is the payment description.",
          redirect_urls: {
            //
            return_url: `${url_FE}${endpoint}success`,
            cancel_url: `${url_FE}${endpoint}cancel`,
          },
        };

        fetch(`${url}pay`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            console.log("response");
            console.log(response);
            return response.json();
          })
          .then((data) => {
            console.log("data");

            console.log(data);
            console.log(data.approval_url);

            setTimeout(() => {
              window.location.href = data.approval_url;
              setLoading(false);
              // handleOpensuccess();

              localStorage.setItem("deposit_amount", values.amount);

              // var InsertAPIURL = `${url}create_payment_paypal-db-wallet`
              // var headers = {
              //     'Accept': 'application/json',
              //     'Content-Type': 'application/json',
              // };
              // var Data = {
              //     "user_id": profiledetails?.data?.user_id,
              //     "amount": values.amount
              // };
              // fetch(InsertAPIURL, {
              //     method: 'POST',
              //     headers: headers,
              //     body: JSON.stringify(Data),
              // })
              //     .then(response => response.json())
              //     .then(response => {
              //         console.log(response);
              //     }
              //     )
              //     .catch(error => {
              //         setLoading(false);
              //         toast.error(error, {
              //             position: toast.POSITION.BOTTOM_CENTER
              //         });
              //     });
            }, 3000);
          })
          .catch((error) => {
            setLoading(false);
            toastAlert("error", "Somehting went wrong");

            console.log(
              "There has been a problem with your fetch operation:",
              error
            );
          });
      }, 2000);
    },
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [profiledetails, setProfiledetails] = useState("");
  const [modalConfirmUpadteBall, setModalConfirmUpadteBall] = useState(false);
  const [selectedballUpdate, setSelectedballUpdate] = useState();

  // const [balls, setBalls] = useState([]);
  const [game, setGame] = useState([]);
  const rows = [1, 2, 3, 4, 5]; // Number of balls per row

  let ballIndex = 0;

  const disabledBalls = [8, 9];
  const [selectedBall, setSelectedBall] = useState(null);
  const [userdetails, setUserdetails] = useState("");
  const [balls, setBalls] = useState([]);
  const [gameusersID, setGameusersID] = useState("");

  const [user_has_selcted_ball, setuser_has_selected_ball] = useState(false);

  const [user_current_selected_balls, setUser_current_selected_balls] =
    useState([]);
  const [user_can_select_ball_restarted, setUser_can_select_ball_restarted] =
    useState(false);
  const [dataResult, setDataResult] = useState(null);
  const [opensuccess, setOpensuccess] = useState(false);

  const getScheduleGame = (details) => {
    var InsertAPIURL = `${url}game/get_scheduled_games?user_id=${details?.data?.user_id}`;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(InsertAPIURL, {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("game", response);

        if (
          response?.data == null ||
          undefined ||
          response?.data?.length == 0
        ) {
          setDataResult(null);
          //   navigate(`${endpoint}dashboard`);
        } else {
          setDataResult(response?.data[0]);

          // start ball images
          setuser_has_selected_ball(response?.data[0]?.user_participated);

          console.log(response?.data[0]);
          let ballData = response.data[0].ball_counts_participants;
          const formattedBalls = Object.keys(ballData).map((key, index) => ({
            id: index + 1,
            imageUrl: ballData[key].imageUrl,
            count: ballData[key].count,
          }));
          setBalls(formattedBalls);
          // end
          // restarted and user slected balls
          let ballDetails1 = response?.data[0]?.user_selected_ball_details;

          if (
            response?.data[0]?.restartedStatus === true &&
            ballDetails1?.length > 0
          ) {
            console.log("VVV");
            const restartedRound = response?.data[0]?.restartedRound;
            console.log("YYYY");
            console.log(restartedRound);

            // Check if any ball detail has `round_no` equal to `restarted_round`
            const hasMatchingRound = ballDetails1.some(
              (ball) => parseInt(ball.round) === parseInt(restartedRound)
            );
            console.log(ballDetails1);

            if (hasMatchingRound) {
              console.log("true");
              setUser_can_select_ball_restarted(false);
            } else {
              console.log("false");
              setUser_can_select_ball_restarted(true);
            }
          } else {
            // If the game is restarted but the user has not selected any ball
            console.log("No balls selected or the game has not restarted");
            setUser_can_select_ball_restarted(true); // Allow the user to select a ball
          }
          //   user participated

          if (
            response?.data[0]?.user_participated === "true" ||
            response?.data[0]?.user_participated === true
          ) {
            console.log("testingg testing", response?.data[0]);

            setUser_current_selected_balls(
              response?.data[0]?.user_selected_ball_details
            );
            // if response?.data[0]?.user_selected_ball_details has only one row then set thaty id to some state .. else get last index id set to state
            let ballDetails = response?.data[0]?.user_selected_ball_details;
            if (ballDetails && ballDetails.length > 0) {
              // If there's only one row, set its ID to state
              if (ballDetails.length === 1) {
                setGameusersID(ballDetails[0].game_user_id);
              } else {
                // If there are multiple rows, set the last row's ID to state
                setGameusersID(
                  ballDetails[ballDetails.length - 1].game_user_id
                );
              }
            }
          }
        }
        setLoader(false);
      })
      .catch((error) => {
        // setLoading(false);
        toastAlert("error", "Something went wrong. Please try again later.");

        setLoader(false);
      });
  };
  const [openlowbalance, setOpenlowbalance] = useState(false);

  const [showConfetti, setShowConfetti] = useState(false);
  const handleopenlowbalance = (data) => {
    setOpenlowbalance(true);
  };
  const handleDeductAmount = (ball) => {
    // console.log(selectedBall);
    setLoading(true);
    // setTimeout(() => {
    var InsertAPIURL = `${url}create_payment_paypal-db-v1`;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    var Data = {
      user_id: profiledetails?.data?.user_id,
      game_id: dataResult?.game_id,
      amount: dataResult?.entry_fee,
    };
    fetch(InsertAPIURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(Data),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response");
        // setLoading(false);
        console.log(response);
        if (response.error) {
          if (
            response.user_already_exist === "true" ||
            response.user_already_exist === true
          ) {
            setLoading(false);
            toastAlert("error", `User is already part of the game.`);
            setOpensuccess(false);
            //   setTimeout(() => {
            //     navigate(`${endpoint}current_game`);
            //   }, 2000);
          } else {
            setLoading(false);
            handleopenlowbalance();
            setOpensuccess(false);
          }
        } else {
          var InsertAPIURL = `${url}game_user/participate`;
          var headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
          };
          var Data = {
            game_users_id: response.data[0].game_users_id,
            winning_ball: selectedBall,
          };
          fetch(InsertAPIURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(Data),
          })
            .then((response) => response.json())
            .then((response) => {
              console.log("fgddgdgd___fgdfgdfg");
              setLoading(false);
              console.log(response);
              if (response.error) {
                setOpensuccess(false);
                toastAlert("error", response.message);
              } else {
                localStorage.setItem(
                  "game_users_id",
                  JSON.stringify(response.data[0].game_users_id)
                );
                toastAlert("success", "Enter Fee submitted successfully!");
                //   navigate(`${endpoint}entryconfirm`);
                setShowConfetti(true);
                setOpensuccess(false);
                setTimeout(() => {
                  setShowConfetti(false);
                }, 5000);
                setLoading(false);
                getScheduleGame(profiledetails);
              }
            })
            .catch((error) => {
              setOpensuccess(false);
              setLoading(false);
              toastAlert("error", "Something went wrong!");
            });
        }
      })
      .catch((error) => {
        setLoading(false);
        toastAlert("error", "Something went wrong!");
      });
    // }, 3000);
  };
  const handleBallChange = (ball) => {
    setLoading(true);
    if (!disabledBalls.includes(ball.id)) {
      setSelectedBall(ball.id);
      // console.log(ball);
      // setOpensuccess(true);
      //   setSelected(ball.imageUrl);

      localStorage.setItem("selectedBall", JSON.stringify(ball));

      console.log("ball ball", ball);

      // setSelectedballUpdate

      var InsertAPIURL = `${url}game_user/participate`;
      var headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      var Data = {
        game_users_id: gameusersID,
        winning_ball: ball.id,
      };
      fetch(InsertAPIURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setLoading(false);
          console.log("update ball", response);
          setModalConfirmUpadteBall(false);
          getScheduleGame(profiledetails);
          toastAlert("success", "Ball Updated Successfully!");
        })
        .catch((error) => {
          setModalConfirmUpadteBall(false);
          setLoading(false);
          toastAlert("error", "Something went wrong!");
        });
    }
  };
  const handleBallClick = (ball) => {
    if (!disabledBalls.includes(ball.id)) {
      if (dataResult?.game_status === "scheduled") {
        // if()
        if (dataResult?.restartedStatus === true) {
          console.log("restartedRound");
          console.log(dataResult?.restartedRound);
          if (user_can_select_ball_restarted) {
            setSelectedBall(ball.id);
            console.log(ball);
            setOpensuccess(true);
            // setSelected(ball.imageUrl);
          } else {
            // console.log("update alert ");
            // console.log(ball);
            // setModalConfirmUpadteBall(true);
            // // handleBallClick(ball)
            // setSelectedballUpdate(ball);
          }
        } else {
          if (
            user_has_selcted_ball === "true" ||
            user_has_selcted_ball === true
          ) {
            setModalConfirmUpadteBall(true);
            // handleBallClick(ball)
            setSelectedballUpdate(ball);
          } else {
            setSelectedBall(ball.id);
            // console.log(ball);
            setOpensuccess(true);
            // setSelected(ball.imageUrl);
            // getScheduleGame(profiledetails);
            localStorage.setItem("selectedBall", JSON.stringify(ball));
          }
        }
      }
    }
  };
  const getUserDetails = (details) => {
    var InsertAPIURL = `${url}transaction_history/get_wallet_value_by_user_id?user_id=${details.data.user_id}`;
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    fetch(InsertAPIURL, {
      method: "GET",
      headers: headers,
      body: JSON.stringify(),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("user", response);
        setUserdetails(response);

        // if (response.data[0] == null || undefined) {
        //     navigate(`${endpoint}dashboard`);
        // } else {
        //     navigate(`${endpoint}playgame`);
        // }
      })
      .catch((error) => {
        // setLoading(false);
        toastAlert("error", "Something went wrong. Please try again later.");
      });
  };

  const [loader, setLoader] = useState(true);

  const details = useSelector((state) => state.user.userProfile);
  const fetchDetails = async () => {
    // If the profile exists in Redux
    if (details) {
      setProfiledetails(details); // Set the profile details state
      console.log("details redux", details);
    }

    try {
      Promise.all([getUserDetails(details), getScheduleGame(details)]); // Await the completion of both getUserDetails and getScheduleGame
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };
  const [openmodaldeposit, setOpenmodaldeposit] = useState(false);

  const handleopenmodaldeposit = (data) => {
    setOpenmodaldeposit(true);
    setOpenlowbalance();
  };
  const typographyProps = {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: { xs: "15px", md: "20px" },
    color: "#000000",
  };

  const valueTypographyProps = {
    ...typographyProps,
    color: "#F5BC01",
  };
  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    // Initialize the socket connection
    const socket = io(url);

    const handleStatusChange = (msg) => {
      console.log("Received message:", msg);
      const { status } = msg;

      const performLoaderAction = async () => {
        setLoader(true);
        await getScheduleGame(details);

        //  setTimeout(() => {
        //     setLoader(false)
        //   }, 500);
      };

      switch (status) {
        case "created":
        case "waiting":
        case "started":
          console.log(`Game ${status}`);
          // toastAlert("success", `Game has ${status}`);

          performLoaderAction();
          break;
        case "result-anounced":
          console.log("Result announced");
          navigate(`${endpoint}winner`);
          break;
        case "restart":
          console.log("Game restart");
          //   toastAlert("success", "Game has restarted");
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 2000);
          performLoaderAction();

          break;
        case "added-participants":
          console.log("Participants added");
          //   getScheduleGame(details);
          performLoaderAction();

          break;
        case "deleted":
          console.log("Game deleted");
          //   toastAlert("error", "Game has deleted");

          performLoaderAction();

          //   navigate(`${endpoint}dashboard`);
          break;
        case "scheduled":
          console.log("Game scheduled");
          //   toastAlert("error", "Game has created");

          performLoaderAction();

          //   navigate(`${endpoint}current_game`);
          break;
        default:
          console.warn("Unknown status received");
      }
    };

    // Set up the socket event listener
    socket.on("received-data", handleStatusChange);

    // Cleanup on unmount
    return () => {
      socket.off("received-data", handleStatusChange);
      socket.disconnect();
    };
  }, [url, endpoint, navigate, getScheduleGame, toastAlert]);

  return (
    <>
      <Sidebar
        componentData={
          <Box
            sx={{
              backgroundColor: "white",
              backgroundSize: "cover",
              width: "100%",
              height: "100vh",
            }}
          >
            {loader ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {showConfetti && (
                  <Confetti
                    width={window.innerWidth} // Full-screen width
                    height={window.innerHeight} // Full-screen height
                    numberOfPieces={500} // Number of confetti pieces
                    gravity={0.3} // Controls how fast confetti falls (lower means slower)
                    wind={0.01} // Controls horizontal drift
                  />
                )}
                {dataResult === null ? (
                  //   if no game running
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "100vh",
                    }}
                  >
                    <Card
                      sx={{
                        mt: { xs: 0, md: 5 },
                        p: 0,
                        borderRadius: "10px",
                        boxShadow: "none",
                        border: "1px solid #F5BC01",
                        width: { xs: "90%", md: "50%" },
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={0}>
                          <Grid xs={6} md={6}>
                            <Stack direction="column">
                              <TypographyMD
                                variant="paragraph"
                                label={t("Available Balance")}
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="left"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={t("Total Played Games")}
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="left"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={t("Won Games")}
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="left"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={t("Lost Games")}
                                color="#000000"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="left"
                              />
                            </Stack>
                          </Grid>

                          <Grid xs={6} md={6}>
                            <Stack direction="column">
                              <TypographyMD
                                variant="paragraph"
                                label={`$ ${userdetails?.total_balance}`}
                                color="#F5BC01"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="right"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={userdetails?.total_played_games}
                                color="#F5BC01"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="right"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={userdetails?.total_won_games}
                                color="#F5BC01"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="right"
                              />

                              <TypographyMD
                                variant="paragraph"
                                label={userdetails?.total_lose_games}
                                color="#F5BC01"
                                marginLeft={0}
                                fontFamily="Rubik"
                                fontSize="16px"
                                fontWeight={450}
                                align="right"
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>

                    <img
                      src={balls1}
                      alt="Balls"
                      style={{ width: "50vh", marginBottom: "20px" }}
                    />

                    <Typography
                      variant="h6"
                      color="#F5BC01"
                      fontFamily="Pacifico"
                      fontSize="30px"
                      sx={{
                        width: { xs: "90%", md: "30%" },
                        textAlign: "center",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {t(
                        "No games yet. 🎱 Get ready for action! Stay tuned. 🌟🔄"
                      )}
                    </Typography>
                  </div>
                ) : (
                  <>
                    {/* game details if schedules */}
                    <Grid container spacing={0}>
                      <Grid xs={12}>
                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {/* start heder  */}
                            {dataResult?.game_status && (
                              <>
                                {dataResult?.game_status === "scheduled" ? (
                                  <>
                                    {/* {} */}
                                    {dataResult?.restartedStatus === true ||
                                    dataResult?.restartedStatus === "true" ? (
                                      <>
                                        <Typography
                                          variant="h6"
                                          color="#F5BC01"
                                          fontFamily="Pacifico"
                                          fontSize="57px"
                                          mt={1}
                                          mb={1}
                                        >
                                          {t("Restart Game")}
                                        </Typography>
                                        {/* {user_can_select_ball_restarted ? (
                                          <>
                                            {" "}
                                            <TypographyMD
                                              variant="paragraph"
                                              label="🎯 Boost your chances! "
                                              color="#000000"
                                              marginLeft={0}
                                              fontFamily="Rubik"
                                              fontSize="25px"
                                              fontWeight={450}
                                              align="right"
                                            />
                                            {user_has_selcted_ball ? (
                                              <TypographyMD
                                                variant="paragraph"
                                                label=" Pick another ball by paying the entry fee! 💰"
                                                color="#000000"
                                                marginLeft={0}
                                                fontFamily="Rubik"
                                                fontSize="25px"
                                                fontWeight={450}
                                                align="right"
                                              />
                                            ) : null}
                                          </>
                                        ) : null} */}
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          variant="h6"
                                          color="#F5BC01"
                                          fontFamily="Pacifico"
                                          fontSize="57px"
                                          mt={1}
                                          mb={1}
                                        >
                                          {t("Play Game")}
                                        </Typography>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                          }}
                                        >
                                          <TypographyMD
                                            variant="paragraph"
                                            label="Pick your winning ball! 🏆🔮"
                                            marginLeft={0}
                                            color="#000000"
                                            fontFamily="Rubik"
                                            fontSize="25px"
                                            fontWeight={450}
                                            align="center"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : null}

                                {dataResult?.game_status === "waiting" ? (
                                  <>
                                    <Typography
                                      variant="h6"
                                      color="#F5BC01"
                                      fontFamily="Pacifico"
                                      fontSize="57px"
                                      mt={1}
                                      mb={1}
                                    >
                                      {t("Waiting ...")}
                                    </Typography>
                                    <TypographyMD
                                      variant="paragraph"
                                      label="Game in Waiting. Starting soon"
                                      color="#000000"
                                      marginLeft={0}
                                      fontFamily="Rubik"
                                      fontSize="25px"
                                      fontWeight={450}
                                      align="right"
                                    />
                                  </>
                                ) : null}
                                {dataResult?.game_status === "started" ? (
                                  <>
                                    <Typography
                                      variant="h6"
                                      color="#F5BC01"
                                      fontFamily="Pacifico"
                                      fontSize="57px"
                                      mt={1}
                                      mb={1}
                                    >
                                      {t("Game started")}
                                    </Typography>

                                    <TypographyMD
                                      variant="paragraph"
                                      label="Get ready, results will be announced soon! ⌛🤞"
                                      color="#000000"
                                      marginLeft={0}
                                      fontFamily="Rubik"
                                      fontSize="25px"
                                      fontWeight={450}
                                      align="right"
                                    />
                                  </>
                                ) : null}

                                {user_has_selcted_ball && (
                                  <>
                                    <TypographyMD
                                      variant="paragraph"
                                      label="SELECTED BALL:"
                                      color="#000000"
                                      fontFamily="Rubik"
                                      fontSize="25px"
                                      fontWeight={450}
                                      align="right"
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                      }}
                                    >
                                      {user_current_selected_balls?.map(
                                        (item) => (
                                          <img
                                            key={item?.ball_image}
                                            src={item?.ball_image}
                                            alt="Selected Ball"
                                            style={{
                                              width: "5vh",
                                              height: "5vh",
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            {/* end header  */}
                          </div>{" "}
                        </Box>
                      </Grid>

                      <Grid xs={12} sm={12} md={5}>
                        <Stack
                          ml={{ xs: 1, md: 15 }}
                          mt={{ xs: 1, md: 5 }}
                          mr={{ xs: 1 }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              backgroundColor: "#ffea96",
                              justifyContent: "space-between",
                              paddingInline: "20px",
                              borderRadius: "50px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={jackpot}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                                marginTop: -5,
                              }}
                            />
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: { xs: "15px", md: "25px" },
                                fontFamily: "Rubik",
                                fontWeight: 550,
                                color: "#000000",
                                textAlign: "left",
                              }}
                            >
                              Jackpot
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 550,
                                fontSize: { xs: "15px", md: "25px" },
                                fontFamily: "Rubik",
                                color: "#000000",
                                textAlign: "right",
                              }}
                            >
                              ${dataResult?.jackpot ?? "$0"}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              mt: { xs: 2, md: 5 },
                              p: 2,
                              borderRadius: "10px",
                              backgroundColor: "white",
                              boxShadow: "none",
                              border: "1px solid #F5BC01",
                              width: "100%",
                            }}
                          >
                            {/* lg */}
                            <Grid
                              container
                              spacing={0}
                              sx={{ display: { xs: "none", md: "flex" } }}
                            >
                              <Grid xs={6} md={6}>
                                <Stack direction="column">
                                  {[
                                    "Participants",
                                    "Entry Fees",
                                    "Game ID",
                                  ].map((label) => (
                                    <TypographyMD
                                      key={label}
                                      variant="paragraph"
                                      label={label}
                                      color="#000000"
                                      {...typographyProps}
                                      align="left"
                                    />
                                  ))}
                                </Stack>
                              </Grid>
                              <Grid xs={6} md={6}>
                                <Stack direction="column">
                                  {[
                                    dataResult?.total_participants ?? 0,
                                    `$${dataResult?.entry_fee ?? 0}`,
                                    `# ${dataResult?.game_id ?? 0}`,
                                  ].map((label, idx) => (
                                    <TypographyMD
                                      key={idx}
                                      variant="paragraph"
                                      label={label}
                                      {...valueTypographyProps}
                                      align="right"
                                    />
                                  ))}
                                </Stack>
                              </Grid>
                            </Grid>

                            {/* xs */}
                            <Grid
                              container
                              spacing={0}
                              sx={{ display: { xs: "flex", md: "none" } }}
                            >
                              <Grid xs={6} md={6}>
                                <Stack direction="column">
                                  {[
                                    "Participants",
                                    "Entry Fees",
                                    "Game ID",
                                  ].map((label) => (
                                    <TypographyMD
                                      key={label}
                                      variant="paragraph"
                                      label={label}
                                      color="#000000"
                                      {...typographyProps}
                                      align="left"
                                    />
                                  ))}
                                </Stack>
                              </Grid>
                              <Grid xs={6} md={6}>
                                <Stack direction="column">
                                  {[
                                    dataResult?.total_participants ?? 0,
                                    `$${dataResult?.entry_fee ?? 0}`,
                                    `# ${dataResult?.game_id ?? 0}`,
                                  ].map((label, idx) => (
                                    <TypographyMD
                                      key={idx}
                                      variant="paragraph"
                                      label={label}
                                      {...valueTypographyProps}
                                      align="right"
                                    />
                                  ))}
                                </Stack>
                              </Grid>
                            </Grid>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid xs={12} sm={12} md={7}>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                          <Typography
                            variant="h6"
                            color="#F5BC01"
                            align="center"
                            fontFamily="Pacifico"
                            fontSize="27px"
                            mt={1}
                            mb={1}
                          >
                            {t("Play Game")}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                            }}
                          >
                            <TypographyMD
                              variant="paragraph"
                              label="Pick your winning ball! 🏆🔮"
                              color="#F5BC01"
                              marginLeft={0}
                              fontFamily="Rubik"
                              fontSize="15px"
                              fontWeight={450}
                              align="center"
                            />
                          </div>
                        </Box>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundImage: `url(${triangle})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: {
                                xs: "contain",
                                sm: "cover",
                                md: "contain",
                              },
                              backgroundPosition: "center", // Positioning the background image
                              width: {
                                xs: "100%",
                                sm: "55vh",
                                md: "90%",
                                lg: "70%",
                              },
                              // width:"60%",
                              // height:"100%",
                              // height:"auto",
                              height: {
                                xs: "290px",
                                sm: "330px",
                                md: "505px",
                                xl: "505px",
                              },
                              objectFit: "contain",
                              //   overFlow:"hidden",
                              display: "flex", // Flexbox properties to center the content
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                ml: { xs: 2, md: 3 },
                                mt: { xs: -5, md: -9 },
                              }}
                            >
                              {rows.map((numBalls, rowIndex) => (
                                <Grid
                                  container
                                  justifyContent="center"
                                  spacing={1}
                                  key={rowIndex}
                                  sx={{ marginBottom: 1 }}
                                >
                                  {Array.from({ length: numBalls }).map(
                                    (_, colIndex) => {
                                      if (ballIndex >= balls.length)
                                        return null;
                                      const ball = balls[ballIndex++];
                                      const isDisabled = disabledBalls.includes(
                                        ball.id
                                      );

                                      return (
                                        <Grid
                                          item
                                          key={colIndex}
                                          sx={{ position: "relative" }}
                                          onClick={() => handleBallClick(ball)}
                                        >
                                          <Avatar
                                            src={ball.imageUrl}
                                            alt={`Ball ${ball.id}`}
                                            sx={{
                                              width: { xs: 30, sm: 40, md: 60 },
                                              height: {
                                                xs: 28,
                                                sm: 35,
                                                md: 58,
                                              },
                                              position: "relative",
                                              cursor: isDisabled
                                                ? "not-allowed"
                                                : "pointer",
                                              "&::after": isDisabled && {
                                                content: '""',
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                                background:
                                                  "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
                                                borderRadius: "50%",
                                              },
                                              filter: isDisabled
                                                ? "grayscale(100%)"
                                                : "none", // Apply grayscale to make it appear disabled
                                              pointerEvents: isDisabled
                                                ? "none"
                                                : "auto", // Disable interaction
                                            }}
                                          />
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              bottom: "70%",
                                              left: "30%",
                                              transform: "translateX(-50%)",
                                              // background: "rgba(0, 0, 0, 0.7)",
                                              color: "#fff",
                                              borderRadius: "4px",
                                              padding: "0 4px",
                                              fontSize: {
                                                xs: "10px",
                                                md: "20px",
                                              },
                                              fontWeight: "bold",
                                            }}
                                          >
                                            <Badge
                                              badgeContent={ball.count}
                                              color="error"
                                              overlap="circular"
                                              anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "left",
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Grid>
                              ))}
                            </Box>
                          </Box>
                        </div>
                        {/* </Stack> */}

                        {/* </div> */}
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Box>
        }
      />
      <ModalWarning
        open={opensuccess}
        onClose={() => setOpensuccess(false)}
        errorImage={false}
        title="Ready to deduct game fee from your wallet?"
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title="Yes, sure"
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={handleDeductAmount}
          />
        }
      />
      <ModalWarning
        open={modalConfirmUpadteBall}
        onClose={() => setModalConfirmUpadteBall(false)}
        title={t("Are you sure to update ball?")}
        errorImage={false}
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title={t("Yes, sure")}
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={() => handleBallChange(selectedballUpdate)}
          />
        }
      />
      <ModalWarning
        open={openlowbalance}
        errorImage={true}
        onClose={() => setOpenlowbalance(false)}
        title="Low Balance Alert! ⚠️  Time to Top Up Your Wallet! 💰 "
        // subheading={`User ${userdetails.status == "unblock" ? "block" : "unblock"} Successfully`}
        data={
          <ButtonMD
            variant="contained"
            title="Deposit Amount"
            width="60%"
            type="submit"
            borderColor="orange"
            backgroundColor="orange"
            borderRadius="10px"
            disabled={loading}
            onClickTerm={handleopenmodaldeposit}
          />
        }
      />
      <ModalAdd
        open={openmodaldeposit}
        onClose={() => setOpenmodaldeposit(false)}
        title="Deposit Amount"
        data={
          <>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <div style={{ padding: 30 }}>
                  <InputfieldCom
                    autoFocus={false}
                    value={formik.values.amount}
                    // onChngeterm={(e) => formik.setFieldValue("amount", e.target.value)}
                    onChngeterm={(e) => {
                      const value = e.target.value;
                      if (value === "" || /^[a-zA-Z0-9]+$/.test(value)) {
                        formik.setFieldValue("amount", value);
                      }
                    }}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                    type="number"
                    variant="outlined"
                    label=""
                    placeholder="Amount"
                    step="0.01"
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <ButtonMD
                      variant="contained"
                      title="Continue"
                      width="60%"
                      type="submit"
                      borderColor="orange"
                      backgroundColor="orange"
                      borderRadius="10px"
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
    </>
  );
}

export default NewDashboard;
