import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import { Box, Divider, Grid, IconButton, Typography, InputAdornment, OutlinedInput, Stack, Card, CardContent, Container, CircularProgress } from "@mui/material";
import TypographyMD from "../components/items/Typography";
import Topbar from "../components/topbar/Topbar";
import { Search } from "@mui/icons-material"
import { NavLink, useNavigate } from 'react-router-dom'
import url from "../url";
import endpoint from "../Endpointurl";
import * as sanitizeHtml from 'sanitize-html';
import { useTranslation } from "react-i18next";
import toastAlert from "../components/toastAlert";

function PrivacyPolicy() {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {

        getUserPrivacyPolicy();

    }, [])

    const [content, setContent] = useState("");

    const getUserPrivacyPolicy = () => {

        var InsertAPIURL = `${url}privacy_policy/get`
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        };
        fetch(InsertAPIURL, {
            method: 'GET',
            headers: headers,
        })
            .then(response => response.json())
            .then(response => {

                console.log(response.data);
                setContent(response.data);

            }
            )
            .catch(error => {
                // setLoading(false);
                toastAlert("error", "Something went wrong. Please try again later");
               
            });

    }



    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoader(false);
        }, 2000); // 2 seconds

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    return (
        <>
            <Sidebar
                componentData={

                    <Box backgroundColor="white" height="100vh">
                        <Container>
                            <Grid container spacing={0}>

                                <Grid xs={12} md={12} sm={12} align="" p={2} >
                                    <TypographyMD variant='paragraph' label={t("Privacy Policy")} color="#424242" marginLeft={0} fontFamily="Rubik" fontSize="30px" fontWeight={550} align="center" />
                                </Grid>

                            </Grid>

                            {loader ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <Grid container spacing={0} sx={{ pl: 2, pr: 2 }} pt={0}>
                                    <Typography variant="body1" fontFamily="Rubik">
                                        <div dangerouslySetInnerHTML={{ __html: content.content }} />
                                        {/* <div>{sanitizeHtml(content.content)}</div> */}
                                    </Typography>
                                </Grid>
                            )}
                        </Container>
                    </Box>

                }
            />


        </>
    )
}

export default PrivacyPolicy;